<!-- 注册户弹窗 -->

<template>
  <div class="content_register">
    <CustomPopup ref="customPopup" @close="reset">
      <div class="tt1">创建一个新账户</div>
      <CustomLine name="姓名" placeholder="请输入姓名" v-model="name" must
        ref="name" />
      <CustomLine
        name="公司名称"
        placeholder="请输入公司名称"
        v-model="companyName"
        ref="companyName"
      />
      <CustomLine
        name="手机号"
        placeholder="请输入手机号"
        v-model="phone"
        must
        type="number"
        :maxLength="11"
        ref="phone"
      />
      <CustomLine
        name="验证码"
        placeholder="请输入验证码"
        v-model="code"
        type="number"
        must
        :maxLength="6"
        ref="code"
      >
        <p
          class="code"
          :class="{ unValid: phoneGetCodeObj.isCodeStart }"
          @click="newPhoneGetCodeFn"
        >
          {{ phoneGetCodeObj.codePlac || "" }}
        </p>
      </CustomLine>
      <CustomLine
        name="密码"
        placeholder="请输入正确的密码(6~12位字母+数字)"
        v-model="pwd"
        :type="isShowPwd?'text':'password'"
        must
        :maxLength="12"
        ref="pwd"
      >
        <img v-if="isShowPwd" @click="isShowPwd=!isShowPwd" src="@/assets/images/eye.png" alt="" class="eye">
        <img v-else @click="isShowPwd=!isShowPwd" src="@/assets/images/closeeye.png" alt="" class="eye">
      </CustomLine>
      <CustomLine name="邮箱号" placeholder="请输入邮箱号" v-model="email" ref="email" />
      <CustomLine
        name="邮箱验证码"
        placeholder="请输入邮箱验证码"
        v-model="emailCode"
        type="number"
        ref="emailCode"
        :maxLength="6"
      >
        <p
          class="code"
          :class="{ unValid: emailGetCodeObj.isCodeStart }"
          @click="emailGetCodeFn"
        >
          {{ emailGetCodeObj.codePlac || "" }}
        </p>
      </CustomLine>
      <button v-loading="submitLoading" @click="register">提交</button>
      <div class="desc">
        已有账号，<span @click="$refs.customPopup.hide()">去登录</span>
      </div>
      <div class="xieyi_box">
        <img
          v-if="isCheck"
          src="@/assets/images/17.png"
          alt=""
          @click="isCheck = !isCheck"
        />
        <img
          v-else
          src="@/assets/images/18.png"
          alt=""
          @click="isCheck = !isCheck"
        />
        <span @click="isCheck = !isCheck">已阅读并同意</span>
        <span class="link" @click="$eventBus.$emit('SHOW_ARGE_POPUP', 1)"
          >《用户注册协议》</span
        >
        <span>与</span>
        <span class="link" @click="$eventBus.$emit('SHOW_ARGE_POPUP', 2)"
          >《隐私政策》</span
        >
      </div>
    </CustomPopup>
  </div>
</template>

<script>
  import CustomPopup from "./CustomPopup.vue";
  import CustomLine from "./CustomLine.vue";
  import { SendCode } from "@/common/codeMultiple.js";
  import { emailReg, phoneReg, pwdReg } from "@/common/config";

  export default {
    components: { CustomLine, CustomPopup },
    data() {
      return {
        name: "",
        companyName: "",
        phone: "",
        pwd: "",
        code: "",
        email: "",
        emailCode: "",
        isCheck: false,
        submitLoading: false,
        phoneGetCodeObj: SendCode.defaultInfo(),
        emailGetCodeObj: SendCode.defaultInfo(),
        isShowPwd:false,
      };
    },
    mounted() {
      this.$eventBus.$on("REGISTER", () => {
        this.$refs.customPopup.show();
      });
    },
    beforeDestroy() {
      this.$eventBus.$off("REGISTER");
    },
    methods: {
      emailGetCodeFn() {
        if (!this.emailGetCodeObj.isRealObject) {
          this.emailGetCodeObj = new SendCode(this.email, 1, {
            accountType: "email",
          });
        }
        this.emailGetCodeObj.getCodeApiHandleFn(this.email);
      },
      newPhoneGetCodeFn() {
        if (!this.phoneGetCodeObj.isRealObject) {
          this.phoneGetCodeObj = new SendCode(this.phone, 1, {});
        }
        this.phoneGetCodeObj.getCodeApiHandleFn(this.phone);
      },
      async register() {
        if (this.submitLoading) return;
        if (!this.name) {
          this.$msg("请输入姓名", "e");
          return;
        }
        // if (!this.companyName) {
        //   this.$msg("请输入公司名称", "e");
        //   return;
        // }
        if (!this.phone) {
          this.$msg("请输入手机号", "e");
          return;
        }
        if (!phoneReg.test(this.phone)) {
          this.$msg("请输入正确的手机号", "e");
          return;
        }
        if (!this.code) {
          this.$msg("请输入验证码", "e");
          return;
        }
        if (!this.pwd) {
          this.$msg("请输入密码", "e");
          return;
        }
        if (!pwdReg.test(this.pwd)) {
          this.$msg("请输入正确的密码(6~12位字母+数字)", "e");
          return;
        }
        if (this.email) {
          if (!emailReg.test(this.email)) {
            this.$msg("请输入正确的邮箱号", "e");
            return;
          } else if (!this.emailCode) {
            this.$msg("请输入邮箱验证码", "e");
            return;
          }
        }
        if (!this.isCheck) {
          this.$msg("请勾选协议", "e");
          return;
        }

        this.submitLoading = true;
        try {
          const res = await this.$post("5cad9f63e4f94", {
            real_name: this.name,
            company_name: this.companyName,
            mobile: this.phone,
            type: 1,
            code: this.code,
            password: this.pwd,
            email: this.email,
            email_code: this.emailCode,
            email_code_type: 1,
          });
          this.$msg("注册成功");
          this.$refs.customPopup.hide();
          this.$store.commit("saveToken", res.data.userinfo.user_token);
          this.$store.commit("saveUserInfo", res.data.userinfo);
          this.$eventBus.$emit("LOGIN_HIDE");
          this.reset();
        } catch (error) {
          this.$msg((error || {}).msg || "失败", "e");
        }
        this.submitLoading = false;
      },
      reset() {
        try { this.$refs.name.clear() } catch (error) { }
        try { this.$refs.companyName.clear() } catch (error) { }
        try { this.$refs.phone.clear() } catch (error) { }
        try { this.$refs.code.clear() } catch (error) { }
        try { this.$refs.pwd.clear() } catch (error) { }
        try { this.$refs.email.clear() } catch (error) { }
        try { this.$refs.emailCode.clear() } catch (error) { }
        this.isCheck = false;
        try {
          this.phoneGetCodeObj.closeFn();
          this.emailGetCodeObj.closeFn();
        } catch (error) {}
        this.$forceUpdate();
      },
    },
  };
</script>

<style lang="scss" scoped>
  button {
    width: 320px;
    height: 48px;
    background: #df3035;
    border-radius: 4px;
    margin: 64px auto 0;
    font-size: 16px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    outline: none;
    display: block;
  }

  .code {
    flex-shrink: 0;
    font-size: 16px;
    color: #df3035;
    margin: 0 0 0 24px;
    cursor: pointer;
    &.unValid {
      color: #999;
    }
  }

  .tt1 {
    font-size: 24px;
    color: #df3035;
    padding-bottom: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
  }

  .desc {
    text-align: center;
    margin-top: 48px;
    font-size: 14px;
    color: #333;
    span {
      color: #df3035;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .xieyi_box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #333;
    margin-top: 48px;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    .link {
      color: #4ec1ff;
      cursor: pointer;
    }
  }

  .eye {
    width: 20px;
    height: 20px;
    margin: 0 0 0 24px;
  }
</style>
