import { post } from '@/common/request'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    userInfo: {},
    newNoticeNum: 0,
    // 备案信息(公司名称)
    companyName: '',
    // 备案信息(备案号)
    beianNo: '',
    // 系统配置信息
    sysInfo: {},
  },
  mutations: {
    /** 初始化用户信息 */
    init(state) {
      var tk = localStorage.getItem('token')
      var ui = localStorage.getItem('user_info')
      this.commit('saveToken', tk || '');
      this.commit('saveUserInfo', JSON.parse(ui) || {});
    },
    /** 保存token */
    saveToken(state, val) {
      state.token = val;
      localStorage.setItem('token', val)
      if (state.token) {
        this.dispatch('getUserInfo');
        this.dispatch('getNewNoticeNum');
      }
    },
    /** 保存userInfo */
    saveUserInfo(state, val) {
      state.userInfo = val;
      localStorage.setItem('user_info', JSON.stringify(val))
    },
    /** 退出 */
    loginOut(state) {
      state.token = '';
      state.userInfo = {};
      localStorage.removeItem('token')
      localStorage.removeItem('user_info')
    }
  },
  getters: {
  },
  actions: {
    // 获取系统新消息数量
    async getNewNoticeNum({ state }) {
      // const res = await post('系统新消息数量接口');
      // state.newNoticeNum = (res.data || {}).system_num || 0;
    },
    // 更新用户信息
    async getUserInfo({ commit }) {
      // const res = await post('用户信息接口');
      // commit('saveUserInfo', res.data || {});
    },
    // 系统配置信息
    async getCSystemInfo({ state }) {
      const res = await post('662338375947d');
      state.companyName = res.data.web_site_title;
      state.beianNo = res.data.web_site_icp;
      state.sysInfo = res.data;
    },
  },
  modules: {
  }
})
