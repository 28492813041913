import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import { eventBus } from "@/main"

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/i18nExample',
    name: 'i18nExample',
    component: resolve => require(['@/views/i18nExample.vue'], resolve),
  },
  {
    path: '/home',
    component: resolve => require(['@/views/home/home.vue'], resolve),
    children: [
      {
        path: "",
        redirect: "index"
      },
      {
        path: 'index',
        component: resolve => require(['@/views/home/_children/index.vue'], resolve),
      },
      {
        path: 'demo2',
        component: resolve => require(['@/views/home/_children/demo2.vue'], resolve),
      },
      {
        path: 'demo3',
        component: resolve => require(['@/views/home/_children/demo3.vue'], resolve),
      },
      {
        path: 'courseLibrary',
        name:"课程库",
        component: resolve => require(['@/views/home/_children/courseLibrary.vue'], resolve),
      },
      {
        path: 'brandStory',
        name:"品牌故事",
        component: resolve => require(['@/views/home/_children/brandStory.vue'], resolve),
      },
      {
        path: 'commonProblem',
        name:"常见问题",
        component: resolve => require(['@/views/home/_children/commonProblem.vue'], resolve),
      },
      {
        path: 'bannerDetail',
        name:"轮播详情",
        component: resolve => require(['@/views/home/_children/bannerDetail.vue'], resolve),
      },
      {
        path: 'courseDetail',
        name:"课程详情",
        component: resolve => require(['@/views/home/_children/courseDetail.vue'], resolve),
      },
      {
        path: 'courseStudy',
        name:"课程学习",
        component: resolve => require(['@/views/home/_children/courseStudy.vue'], resolve),
      },
      {
        path: 'submitOrder',
        name:"提交订单",
        component: resolve => require(['@/views/home/_children/submitOrder.vue'], resolve),
      },
      {
        path: 'pay',
        name:"支付",
        component: resolve => require(['@/views/home/_children/pay.vue'], resolve),
      },
      {
        path: 'bankTransferSubmitSuccess',
        name:"银行转账提交成功",
        component: resolve => require(['@/views/home/_children/bankTransferSubmitSuccess.vue'], resolve),
      },
      {
        path: 'wechatAndAliPaySuccess',
        name:"微信支付宝支付成功",
        component: resolve => require(['@/views/home/_children/wechatAndAliPaySuccess.vue'], resolve),
      },
      {
        path: 'exam',
        name:"考试",
        component: resolve => require(['@/views/home/_children/exam.vue'], resolve),
      },
      {
        path: 'mine',
        name:"我的",
        component: resolve => require(['@/views/home/_children/mine/mine.vue'], resolve),
        children:[
          {
            path: "",
            redirect: "mineIndex"
          },
          {
            path: 'mineIndex',
            name:"我的主页",
            component: resolve => require(['@/views/home/_children/mine/mineIndex.vue'], resolve),
          },
          {
            path: 'mineOrder',
            name:"我的订单",
            component: resolve => require(['@/views/home/_children/mine/mineOrder.vue'], resolve),
          },
          {
            path: 'accountSet',
            name:"账户设置",
            component: resolve => require(['@/views/home/_children/mine/accountSet.vue'], resolve),
          },
          {
            path: 'myCourse',
            name:"我的课程",
            component: resolve => require(['@/views/home/_children/mine/myCourse.vue'], resolve),
          }
        ]
      }
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 白名单
const whiteRoute = [
  '/login',
  '/login/forgotpassword',
  "/home/index",
  "/home/index",
  "/home/courseLibrary",
  "/home/brandStory",
  "/home/commonProblem",
  "/home/bannerDetail",
  "/home/courseDetail",
]

// 路由拦截
router.beforeEach((to, form, next) => {
  var tk = localStorage.getItem('token');
  if (!tk && !whiteRoute.some(t => t == to.path)) {
    eventBus.$emit("LOGIN")
    // next({ path: '/login' })
  }
  else next()
})

export default router
