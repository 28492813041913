<template>
    <div :id="videoId"></div>
</template>

<script>
import Player from 'xgplayer';
import 'xgplayer/browser/xgplayer.min.css';

export default {
    props: {
        autoplay: Boolean,
        src: String,
        poster: String,
    },
    data() {
        return {
            videoId: "mse" + parseInt(Math.random() * 10000) + Date.now(),
            player: null,
        }
    },
    mounted() {
        this.player = new Player({
            id: this.videoId,
            url: this.src,
            poster: this.poster,
            playbackRate: [0.5, 0.75, 1, 1.5, 2],// 倍速配置
            autoplay: this.autoplay,
            width: "100%",
            height: "100%"
        });
    },
    methods: {
        play() { this.player.play() },
        pause() { this.player.pause() },
    }
}
</script>
