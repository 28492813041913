const projectConfig = require('./../../projectConfig.json')
var apiBase = projectConfig.devHost;
if (process.env.NODE_ENV == 'production') apiBase = projectConfig.productionHost;
export const apiFix = projectConfig.postVersion;
export const uploadUrl = projectConfig.uploadUrl;
export const isApiDev = projectConfig.isApiDev;
export const getCodeApiUrl = projectConfig.getCodeApiUrl;

// -------------------------------------------------------


export default {
  apiFix,
  apiBase,
  uploadUrl,
  uploadBaseUrl: `${apiBase}/api/${apiFix}/${uploadUrl}`,
  uploadHeader: { 'user-token': localStorage.getItem('token') },
}

/** 密码正则(需要包含字母和数字的12位密码) */
export const pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
/** 手机号正则 */
export const phoneReg = /^1[3-9]\d{9}$/;
/** 身份证号正则 */
export const idCardReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
/** 有效输入过滤【只保留字母加数字】 */
export const validStrReg = /[^A-Za-z0-9]/g;
/** 银行卡号正则 */
export const bankRegFn = (num = '') => /^(62|88|56)\d{14}$/.test(num) || /^(62|88|56)\d{17}$/.test(num);
/** 姓名正则 */
export const realNameReg = /^[\u4e00-\u9fa5]{2,4}$/;
/** 邮箱正则 */
export const emailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;