import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// element ui
import ElementUI, { Notification } from 'element-ui';
// 默认主题色
// import 'element-ui/lib/theme-chalk/index.css';
// 自定义主题色（通过官网主题色下载）
import './common/el_css/index.css';
Vue.use(ElementUI);
import lang from 'element-ui/lib/locale/lang/en'
import langZh from 'element-ui/lib/locale/lang/zh-CN'
import langZhFan from 'element-ui/lib/locale/lang/zh-TW'
import locale from 'element-ui/lib/locale'
(function () {
  const types = ["ENG", "简", "繁"];
  let index = types.findIndex(type => type == localStorage.getItem('lang'));
  if (!~index) index = 1;
  const elementTypeNames = [lang, langZh, langZhFan]
  locale.use(elementTypeNames[index])
})()

// custom components
import CPagination from './components/CPagination.vue';
Vue.component('CPagination', CPagination)
import Container from "./components/Container.vue"
Vue.component("Container", Container)

// axios
import { post } from './common/request'
import './common/request'
Vue.prototype.$post = post;

// msg
export const msg = function (title = '', type) {
  Notification({
    title,
    type: type == 'e' ? 'error' : 'success'
  })
};
Vue.prototype.$msg = msg;

// 全局事件对象
// this.$eventBus.$emit("")
export const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;

// 环境变量
Vue.prototype.isdev = process.env.NODE_ENV == "development";

// 自定义组件(客服按钮)
Vue.component("ServerBtn", () => import("./components/ServerBtn.vue"))

// 联系我们公共方法
Vue.prototype.$contactUs = function () {
  // eventBus.$emit("showServerPopup");
  window.open("https://tb.53kf.com/code/client/af9b554627b0780c7ebc50f026c86bd16/1")
}

// 自定义导航
Vue.prototype.$nav = function (path, query) {
  this.$router.push({ path, query: {method:"push",...query} })
}
Vue.prototype.$navRp = function (path, query) {
  this.$router.replace({ path, query: {method:"push",...query} })
}

// 全局混入
Vue.mixin({
  data() {
    return {
      containerMinWidth: "1024px",
      containerMaxWidth: "1400px",
    }
  },
  computed: {
    isLogin() { return !!this.$store.state.token },
    userInfo() { return this.$store.state.userInfo || {} }
  },
  methods: {
    // 重新获取用户信息
    async resetGetUserInfo() {
      if (!this.isLogin) return;
      var res = await this.$post(`5c78c4772da97`, {});
      if (res.code == 1) this.$store.commit('saveUserInfo', res.data || {});
    },
    // 复制
    copyFn(str = '') { if (!str) return; this.$copyText(str); this.$msg('复制成功'); },
  }
});

// 自定义播放器
import MyVideo from "@/components/MyVideo.vue"
Vue.component("MyVideo", MyVideo)

// use copy
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

// 导出插件
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

// 多语言
import i18n from './i18n'
Vue.use(
  {
    i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
  }
)

// vue 示例
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
