/*
    ===============example==携带手机号进入=============

    html---

        <div class="code" :class="{gray: sendCodeObj.isCodeStart}" @click="sendCodeObj.getCodeApiHandleFn()" v-loading="sendCodeObj.getCodeLoading">{{ sendCodeObj.codePlac||'' }}</div>

    js---

        data(){return {sendCodeObj: {}}},
        created(){
            this.sendCodeObj = new SendCode('13000000000',1)
        }

    ===============example==手动输入进入==============

    html---

        <span
            class="valid"
            :class="{unValid: newPhoneGetCodeObj.isCodeStart}"
            @click="newPhoneGetCodeFn"
        >{{ newPhoneGetCodeObj.codePlac||'' }}</span>

    js---

        data(){return {newPhoneGetCodeObj: SendCode.defaultInfo(),}}
        methods: {
            newPhoneGetCodeFn() {
                if (!/^1[3-9]\d{9}$/.test(this.form.mobile)) return this.$msg('请正确输入手机号', 'e');
                if (!this.newPhoneGetCodeObj.isRealObject) this.newPhoneGetCodeObj = new SendCode(this.form.mobile, 5, {user_id: this.userInfo.id});
                this.newPhoneGetCodeObj.getCodeApiHandleFn(this.form.mobile);
            },
        }
*/

import { msg as msgFn } from "@/main";
import { getCodeApiUrl, isApiDev } from "./config";
import { post } from "./request";

/**
 * @description 发送验证码
 * @property {String} mobile 手机号 
 * @event {Function()} close 关闭获取验证码
 */
export class SendCode {
    static DEFAULT_TIME = 60;
    handlerTimer = SendCode.DEFAULT_TIME;
    downTimer = null;
    codePlac = '获取验证码';
    isCodeStart = false;
    getCodeLoading = false;
    codeType = 1;
    account = '';
    isRealObject = true;
    user_id = '';
    accountType = "mobile"; // mobile:手机号  email:邮箱

    constructor(account, type = 1, otherInfo = {}) {
        this.codeType = type;
        this.account = account;
        this.user_id = otherInfo.user_id || '';
        this.accountType = otherInfo.accountType || "mobile";
    }

    // 验证表单信息
    verifyFn() {
        var msgDesc = '';
        if(this.accountType == 'mobile'){
            if (!this.account) msgDesc = '请输入手机号'
            else if (!this.authedticationMobile(this.account)) msgDesc = '手机号输入错误请重新输入'
        } else {
            if (!this.account) msgDesc = '请输入邮箱'
            else if (!this.authedticationEmail(this.account)) msgDesc = '邮箱输入错误请重新输入'
        }
        msgDesc && msgFn(msgDesc, 'e');
        return !msgDesc;
    }

    // 是否为有效的手机号
    authedticationMobile(mobile = '') { return /^1[3-9]\d{9}$/.test(mobile) }

    // 是否为有效的邮箱
    authedticationEmail(email = '') { return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email) }

    // 开始倒计时
    startHandlerDownTimeFn() {
        if (this.isCodeStart) return;
        this.isCodeStart = true;
        this.codePlac = `${this.handlerTimer}重新获取`;
        this.downTimer = setInterval(() => {
            this.handlerTimer--;
            if (this.handlerTimer <= 0) {
                clearInterval(this.downTimer)
                this.handlerTimer = SendCode.DEFAULT_TIME;
                this.isCodeStart = false;
                this.codePlac = '获取验证码';
            } else {
                this.codePlac = `${this.handlerTimer}重新获取`;
            }
        }, 1000);
    }

    /**
     * 发送验证码
     * @param {String} account 手机号/邮箱（不传使用创建对象时输入的手机号/邮箱）
     */
    async getCodeApiHandleFn(account = '') {
        if (this.authedticationMobile(account) || this.authedticationEmail(account)) this.account = account;
        if (this.isCodeStart || this.getCodeLoading || !this.verifyFn()) return;
        this.getCodeLoading = true;
        try {
            const res = await post(getCodeApiUrl, {
                account_type: this.accountType,
                account: this.account,
                type: this.codeType,
                is_test: Number(isApiDev),
                user_id: this.user_id || undefined,
            });
            this.startHandlerDownTimeFn();
            msgFn(res.msg + ((res.data || {}).code || ''));
        } catch (error) { msgFn((error || {}).msg || '获取验证码失败', 'e'); console.debug(error) }
        this.getCodeLoading = false;
    }

    // 关闭倒计时
    closeFn() {
        clearInterval(this.downTimer);
        this.handlerTimer = SendCode.DEFAULT_TIME;
        this.codePlac = '获取验证码';
        this.isCodeStart = false;
        this.getCodeLoading = false;
    }

    // 页面占位符
    static defaultInfo() {
        return {
            codePlac: '获取验证码',
            getCodeApiHandleFn() { },
            isRealObject: false,
        }
    }
}
