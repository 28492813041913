<!-- 协议页面 -->

<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :show-close="false"
      :close-on-press-escape="false"
      custom-class="custom-popup"
    >
      <div class="tt_box">
        <span>{{ title }}</span>
        <img
          src="@/assets/images/16.png"
          alt=""
          class="close"
          @click="isShow = false"
        />
      </div>
      <div class="arge_content">
        <div v-if="type == '1'" v-html="userArge"></div>
        <div v-if="type == '2'" v-html="privacyPolicy"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isShow: false,
        title: "",
        type: "", // 1:用户协议 2:隐私政策
        userArge:"",
        privacyPolicy: "",
      };
    },
    mounted() {
      this.$eventBus.$on("SHOW_ARGE_POPUP", (type) => {
        this.title = type == "1" ? "用户协议" : "隐私政策";
        this.type = type;
        this.isShow = true;
      });
      this.$post("66233842eb900",{aid:1}).then((res) => {
        this.userArge = res.data.content;
      });
      this.$post("66233842eb900",{aid:2}).then((res) => {
        this.privacyPolicy = (res.data||{}).content;
      })
    },
    beforeDestroy() {
      this.$eventBus.$off("SHOW_ARGE_POPUP");
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog__header {
    display: none;
  }

  ::v-deep .custom-popup {
    width: 1000px;
    .el-dialog__body {
      padding: 0;
      box-sizing: border-box;
    }
  }

  .tt_box {
    position: relative;
    text-align: center;
    background-color: #f8f8f8;
    height: 56px;
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    line-height: 56px;

    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .arge_content {
    padding: 30px 50px;
    max-height: 80vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
</style>
