import axios from 'axios'
import configInfo from './config'
import store from '@/store'
import router from '@/router'

const publicAxios = axios.create({
  timeout: 10000,
  baseURL: configInfo.apiBase,
})

// 请求拦截
publicAxios.interceptors.request.use(function (config) {
  var tk = localStorage.getItem('token')
  if (tk) config.headers['user-token'] = tk
  config.url = `/api/${configInfo.apiFix}/${config.url}`
  return config;
}, function (err) {
  return Promise.reject((err || {}).data)
})
publicAxios.interceptors.response.use(function (res) {
  if (res.data.code == 1) return res.data;
  else if (res.data.code == '-201' || res.data.code == '-202' || res.data.code == '-203') {
    store.commit('loginOut');
    router.replace('/');
  }
  return Promise.reject(res.data);
}, function (err) {
  return Promise.reject((err || {}).data)
})

// 导出请求
export function post(url, data) {
  return publicAxios.post(url, data)
}

// 图片上传 
export function uploadFn(file) {
  const formData = new FormData()
  // formData.append('module', '');
  formData.append('dir', '');
  formData.append('file', file);
  return publicAxios({
    method: 'post',
    url: configInfo.uploadUrl,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

// oss上传 
export function ossUpload(file, configInfo) {
  return new Promise(async (resolve, reject) => {
    const filePath = `pc/${file.name}`
    const url = `${configInfo.host}/${encodeURIComponent(filePath)}`
    const headers = {
      'Content-Type': 'multipart/form-data',
      'x-oss-access-key-id': configInfo.accessKeyId,
      // 'x-oss-security-token': "",
      'x-oss-signature': configInfo.signature,
      'x-oss-date': new Date().toISOString()
    }
    try {
      const res = await axios.put(url, file, { headers })
      if (res.request.status == 200) {
        resolve(res.request.responseURL)
      } else {
        reject(res.data)
      }
    } catch (error) {
      reject(error || {})
    }
  })
}