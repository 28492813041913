<!-- 页面内容 -->

<template>
  <div class="line_box" @click="$emit('click')">
    <p>
      <span v-if="must" style="color: #f00">*</span>
      {{ name }}
    </p>
    <div class="line_content" v-if="!hideContent">
      <input
        :type="type"
        v-model="iptVal"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
      />
      <slot></slot>
    </div>
    <slot name="other"></slot>
  </div>
</template>

<script>
  export default {
    name:"CustomLine",
    props: {
      value: [String, Number],
      placeholder: String,
      name: String,
      type: {
        type: String,
        default: "text",
      },
      must: Boolean,
      disabled: Boolean,
      hideContent: Boolean,
      maxLength: {
        type: Number,
        default: 10000,
      },
    },
    data() {
      return {
        iptVal: "",
      };
    },
    watch: {
      value: {
        handler(val) {
          if(val){
            this.iptVal = val.slice(0, this.maxLength);
          } else this.iptVal = val;
        },
        immediate:true,
        deep: true,
      },
      iptVal(val) {
        this.$emit("input", val);
      },
    },
    methods: {
      clear(){this.iptVal = ""}
    },
  };
</script>

<style lang="scss" scoped>
  .line_box {
    font-size: 18px;
    color: #333333;
    padding: 20px 0 0;
    font-family: PingFang SC, PingFang SC;
  }

  .line_content {
    margin: 8px 0 0;
    padding: 8px 16px;
    border-radius: 4px;
    background: #f8f8f8;
    display: flex;
    align-items: center;

    input {
      flex: 1;
      border: none;
      background: none;
      outline: none;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      &::placeholder {
        color: #999999;
      }
    }
  }
</style>
