let timer = null;
const maxNoticeNum = 30;
let noticeNum = maxNoticeNum;

// 重置提示
function resetNoticeNum() {
    clearInterval(timer);
    noticeNum = maxNoticeNum;
}

// 网络监听
export default function () {
    // 网络恢复了
    window.ononline = () => resetNoticeNum()
    // 断网了
    window.onoffline = () => {
        timer = setInterval(() => {
            noticeNum--;
            if (noticeNum > 0) this.$msg(this.$t("netWorkError"), 'e')
            else resetNoticeNum();
        }, 3000);
    }
}
