<template>
    <div
        id="app"
        style="min-width: 1007px;"
        ref="scroll"
    >
        <!-- 显示容器 -->
        <router-view />
        <LoginPopup />
        <ForgotPassword />
        <Register />
        <ArgsPopup />
        <div class="server_popup_box">
            <CPopup v-model="isShowServerPopup" title="客服"><iframe src="" style="height:60vh;" /></CPopup>
        </div>
    </div>
</template>

<script>
import "@/common/version"
import onNetWorkStatusChange from '@/common/onNetWorkStatusChange';
import LoginPopup from "./views/login/LoginPopup.vue"
import ForgotPassword from "./views/login/ForgotPassword.vue"
import Register from "./views/login/Register.vue"
import ArgsPopup from "./views/login/ArgsPopup.vue"
import CPopup from "./components/CPopup.vue"

export default {
    components:{ LoginPopup, ForgotPassword, Register, ArgsPopup, CPopup },
    data() {
        return {
            isShowServerPopup:false,
        }
    },
    watch:{
        '$route'(to, from) {
            if(to.path == from.path) return;
            if(to.query.method == "push" || to.query.method == "replace"){
                window.scrollTo(0, 0)
            }
        }
    },
    created() {
        this.setRem();
        window.addEventListener("resize", () => this.setRem())

        this.$store.commit('init');
        this.$store.dispatch('getCSystemInfo');

        onNetWorkStatusChange.call(this);
        this.resetGetUserInfo();

        this.$eventBus.$on("showServerPopup", () => this.isShowServerPopup = true)
    },
    beforeDestroy() { window.removeEventListener("resize", () => this.setRem()) },
    methods: {
        setRem() {
            const size = window.innerWidth / 1920 * 16;
            document.documentElement.style = `font-size:${size < 12 ? 12 : size}px`;
        }
    }
}
</script>

<style lang="scss">
@import './common/css/global.scss';
@import './common/css/flex.scss';
@import './common/css/animate.min.css';

@font-face {
 font-family: 'Source Han Sans SC';
 src: url('~@/assets/fonts/SourceHanSansSC-Normal-2.otf');
}

@font-face {
 font-family: 'DingTalk JinBuTi';
 src: url('~@/assets/fonts/DingTalk JinBuTi.ttf');
}

#app {
 font-size: 16px;
 font-family: 'PingFang SC';
 font-weight: 400;
}

.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
 opacity: 0;
}

.server_popup_box ::v-deep .el-dialog{
    width:70vw!important;
}
</style>
