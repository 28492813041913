<!-- 忘记密码弹窗 -->

<template>
  <div class="content">
    <CustomPopup ref="customPopup">
      <div class="tt1">忘记登录</div>
      <CustomLine
        name="手机号"
        placeholder="请输入手机号"
        v-model="phone"
        type="number"
        :maxLength="11"
        ref="phone"
      />
      <CustomLine
        name="验证码"
        placeholder="请输入验证码"
        v-model="code"
        type="number"
        ref="code"
        :maxLength="6"
      >
        <p
          class="code"
          :class="{ unValid: phoneGetCodeObj.isCodeStart }"
          @click="phoneGetCodeFn"
        >
          {{ phoneGetCodeObj.codePlac || "" }}
        </p>
      </CustomLine>
      <CustomLine
        name="密码"
        placeholder="请输入密码(6~12位字母+数字)"
        v-model="pwd"
        :type="isShowPwd?'text':'password'"
        ref="pwd"
        :maxLength="12"
      >
        <img v-if="isShowPwd" @click="isShowPwd=!isShowPwd" src="@/assets/images/eye.png" alt="" class="eye">
        <img v-else @click="isShowPwd=!isShowPwd" src="@/assets/images/closeeye.png" alt="" class="eye">
      </CustomLine>
      <button v-loading="submiting" @click="submit">提交</button>
    </CustomPopup>
  </div>
</template>

<script>
  import CustomPopup from "./CustomPopup.vue";
  import CustomLine from "./CustomLine.vue";
  import { phoneReg, pwdReg } from "@/common/config";
  import { SendCode } from "@/common/codeMultiple";

  export default {
    components: { CustomLine, CustomPopup },
    data() {
      return {
        phone: "",
        pwd: "",
        code: "",
        submiting: false,
        phoneGetCodeObj: SendCode.defaultInfo(),
        isShowPwd:false,
      };
    },
    mounted() {
      this.$eventBus.$on("FORGOT_PASSWORD", () => {
        this.$refs.customPopup.show();
      });
    },
    beforeDestroy() {
      this.$eventBus.$off("FORGOT_PASSWORD");
    },
    methods: {
      phoneGetCodeFn() {
        if (!this.phoneGetCodeObj.isRealObject) {
          this.phoneGetCodeObj = new SendCode(this.phone, 2, {});
        }
        this.phoneGetCodeObj.getCodeApiHandleFn(this.phone);
      },
      async submit() {
        if (this.submiting) return;
        if (!this.phone) {
          this.$msg("请输入手机号", "e");
          return;
        }
        if (!phoneReg.test(this.phone)) {
          this.$msg("手机号格式不正确", "e");
          return;
        }
        if (!this.code) {
          this.$msg("请输入验证码", "e");
          return;
        }
        if (!this.pwd) {
          this.$msg("请输入密码", "e");
          return;
        }
        if (!pwdReg.test(this.pwd)) {
          this.$msg("请输入正确的密码(6~12位字母+数字)", "e");
          return;
        }
        this.submiting = true;
        try {
          const res = await this.$post("66e3013370673", {
            mobile: this.phone,
            code: this.code,
            type: 2,
            password: this.pwd,
            account_type: 'mobile'
          });
          this.resetForm();
          this.$msg(res.msg || "提交成功");
          this.$eventBus.$emit("LOGIN");
          this.$refs.customPopup.hide();
        } catch (error) {
          this.$msg((error || {}).msg || "提交失败", "e");
        }
        this.submiting = false;
      },
      resetForm() {
        try { this.$refs.phone.clear() } catch (error) { }
        try { this.$refs.code.clear() } catch (error) { }
        try { this.$refs.pwd.clear() } catch (error) { }
        try {
          this.phoneGetCodeObj.closeFn();
        } catch (error) {console.debug(error)}
        this.$forceUpdate();
      }
    },
  };
</script>

<style lang="scss" scoped>
  button {
    width: 320px;
    height: 48px;
    background: #df3035;
    border-radius: 4px;
    margin: 64px auto 0;
    font-size: 16px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    outline: none;
    display: block;
  }

  .code {
    flex-shrink: 0;
    font-size: 16px;
    color: #df3035;
    margin: 0 0 0 24px;
    cursor: pointer;
    &.unValid {
      color: #999999;
    }
  }

  .tt1 {
    font-size: 24px;
    color: #df3035;
    padding-bottom: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
  }

  .eye {
    width: 20px;
    height: 20px;
    margin: 0 0 0 24px;
  }
</style>
