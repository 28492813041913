<!-- 登录弹窗 -->

<template>
  <div class="content_login">
    <CustomPopup ref="customPopup">
      <div class="tab_list">
        <div
          class="tab_item"
          v-for="(name, index) in tabData"
          :key="index"
          :class="{ active: tabIndex == index }"
          @click="tabIndex = index"
        >
          {{ name }}
        </div>
      </div>
      <!-- 密码登录 -->
      <CustomLine
        name="手机号"
        placeholder="请输入手机号"
        v-model="phone"
        type="number"
        :maxLength="11"
        ref="phone"
      />
      <CustomLine
        v-if="tabIndex == 0"
        name="密码"
        placeholder="请输入密码(6~12位字母+数字)"
        v-model="pwd"
        :type="isShowPwd?'text':'password'"
        ref="pwd"
        :maxLength="12"
      >
        <img v-if="isShowPwd" @click="isShowPwd=!isShowPwd" src="@/assets/images/eye.png" alt="" class="eye">
        <img v-else @click="isShowPwd=!isShowPwd" src="@/assets/images/closeeye.png" alt="" class="eye">
      </CustomLine>
      <CustomLine
        v-else
        name="验证码"
        placeholder="请输入验证码"
        v-model="code"
        type="number"
        ref="code"
        :maxLength="6"
      >
        <p
          class="code"
          :class="{ unValid: phoneGetCodeObj.isCodeStart }"
          @click="phoneGetCodeFn"
        >
          {{ phoneGetCodeObj.codePlac || "" }}
        </p>
      </CustomLine>
      <div class="check_out_box">
        <div class="check_box" @click="checked=!checked">
          <img v-if="checked" src="@/assets/images/38.png" alt="">
          <img v-else src="@/assets/images/39.png" alt="">
          <span style="color: #999999">记住我</span>
        </div>
        <span
          style="cursor: pointer"
          @click="$eventBus.$emit('FORGOT_PASSWORD', true)"
          >忘记密码</span
        >
      </div>
      <button v-loading="submitLoading" @click="toLogin">登录</button>
      <div class="create_account" @click="$eventBus.$emit('REGISTER', true)">
        创建一个新账户
      </div>

    </CustomPopup>
  </div>
</template>

<script>
  import CustomPopup from "./CustomPopup.vue";
  import CustomLine from "./CustomLine.vue";
  import { phoneReg, pwdReg } from "@/common/config";
  import { SendCode } from "@/common/codeMultiple";

  export default {
    components: { CustomLine, CustomPopup },
    data() {
      return {
        isShow: true,
        tabData: ["密码登录", "验证码登录"],
        tabIndex: 0,
        phone: "",
        pwd: "",
        code: "",
        checked: false,
        submitLoading: false,
        phoneGetCodeObj: SendCode.defaultInfo(),
        isShowPwd:false,
      };
    },
    mounted() {
      this.$eventBus.$on("LOGIN", () => {
        this.$refs.customPopup.show();
      });
      this.$eventBus.$on("LOGIN_HIDE", () => {
        this.$refs.customPopup.hide();
      });
    },
    beforeDestroy() {
      this.$eventBus.$off("LOGIN");
      this.$eventBus.$off("LOGIN_HIDE");
    },
    methods: {
      phoneGetCodeFn() {
        if (!this.phoneGetCodeObj.isRealObject) {
          this.phoneGetCodeObj = new SendCode(this.phone, 3, {});
        }
        this.phoneGetCodeObj.getCodeApiHandleFn(this.phone);
      },
      async toLogin() {
        if (this.submitLoading) return;
        if (!this.phone) {
          this.$msg("请输入手机号", "e");
          return;
        }
        if (!phoneReg.test(this.phone)) {
          this.$msg("请输入正确的手机号", "e");
          return;
        }
        if (this.tabIndex == 0) {
          if (!this.pwd) {
            this.$msg("请输入密码", "e");
            return;
          }
          if (!pwdReg.test(this.pwd)) {
            this.$msg("请输入正确的密码(6~12位字母+数字)", "e");
            return;
          }
          this.submitLoading = true;
          await this.pwdLogin();
        } else {
          if (!this.code) {
            this.$msg("请输入验证码", "e");
            return;
          }
          this.submitLoading = true;
          await this.codeLogin();
        }
        this.submitLoading = false;
      },
      async pwdLogin() {
        try {
          const res = await this.$post("5c78dbfd977cf", {
            mobile: this.phone,
            password: this.pwd,
          });
          this.$store.commit("saveToken", res.data.userinfo.user_token);
          this.$store.commit("saveUserInfo", res.data.userinfo);
          this.$refs.customPopup.hide();
          this.$msg("登录成功");
          this.reset();
        } catch (error) {
          this.$msg((error || {}).msg, "e");
        }
      },
      async codeLogin() {
        try {
          const res = await this.$post("5c78dca45ebc1", {
            mobile: this.phone,
            type: 3,
            code: this.code,
          });
          this.$store.commit("saveToken", res.data.userinfo.user_token);
          this.$store.commit("saveUserInfo", res.data.userinfo);
          this.$refs.customPopup.hide();
          this.$msg("登录成功");
          this.reset();
        } catch (error) {
          this.$msg((error || {}).msg, "e");
        }
      },
      reset() {
        try { this.$refs.phone.clear() } catch (error) { }
        try { this.$refs.code.clear() } catch (error) { }
        try { this.$refs.pwd.clear() } catch (error) { }
        try {
          this.phoneGetCodeObj.closeFn();
        } catch (error) {console.debug(error)}
        this.$forceUpdate();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tab_list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 32px;
    .tab_item {
      font-family: PingFang SC, PingFang SC;
      font-size: 20px;
      color: #999999;
      position: relative;
      cursor: pointer;

      &.active {
        font-size: 24px;
        color: #df3035;

        &::after {
          background-color: #df3035;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 36px;
        transform: translateX(-50%);
        width: 24px;
        height: 5px;
      }
    }
  }
  .check_out_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 14px;
    color: #999999;
  }
  button {
    width: 320px;
    height: 48px;
    background: #df3035;
    border-radius: 4px;
    margin: 64px auto 0;
    font-size: 16px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    outline: none;
    display: block;
  }

  .code {
    flex-shrink: 0;
    font-size: 16px;
    color: #df3035;
    margin: 0 0 0 24px;
    cursor: pointer;
    &.unValid {
      color: #999999;
    }
  }
  .create_account {
    font-size: 14px;
    color: #df3035;
    width: max-content;
    margin: 48px auto 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .check_box {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }

  .eye {
    width: 20px;
    height: 20px;
    margin: 0 0 0 24px;
  }
</style>
