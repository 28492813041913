<!-- 自定义弹窗 -->

<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      custom-class="custom-popup"
    >
      <img
        src="@/assets/images/16.png"
        alt=""
        class="close"
        @click="isShow = false"
      />
      <slot></slot>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isShow: false,
      };
    },
    watch:{
      isShow(val){val || this.$emit("close")}
    },
    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog__header {
    display: none;
  }

  ::v-deep .custom-popup {
    width: 780px;
    border-radius: 16px;
    .el-dialog__body {
      padding: 72px 200px;
      position: relative;
      box-sizing: border-box;

      .close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
</style>
