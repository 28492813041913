<!-- 页面内容 -->

<template>
  <div :style="css" style="max-width: 1260px; margin: 0 auto">
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      css: String,
    },
  };
</script>
