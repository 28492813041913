<!-- 自定义弹窗 -->

<template>
  <el-dialog
    :title="title || ''"
    :visible.sync="isShow"
    :show-close="false"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="false"
    top="10vh"
    @close="$emit('close')"
  >
    <div style="overflow: hidden; border-radius: 16px">
      <div class="custom_head">
        <p class="title">{{ title || "标题" }}</p>
        <div class="close_out_box">
          <slot name="title_right_icon" />
          <img
            v-if="isShowCloseBtn"
            src="@/assets/images/16.png"
            alt=""
            class="close"
            @click="isShow = false"
          />
        </div>
      </div>
      <div class="custom_content">
        <div class="custom_head_plac" />
        <div class="content_in_box">
          <slot />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      /** 是否显示 */
      value: Boolean,
      /** 标题 */
      title: String,
      /** 点击阴影关闭弹窗 */
      closeOnClickModal: {
        type: Boolean,
        default: true,
      },
      /** 是否显示关闭按钮 */
      isShowCloseBtn: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      isShow: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        },
      },
    },
    methods: {
      closeFn() {
        if (this.closeOnClickModal) this.isShow = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog__header {
    height: 0;
    padding: 0;
    opacity: 0;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
    color: #333;
    background-color: #fff;
    border-radius: 16px;
  }

  ::v-deep .el-dialog {
    background-color: transparent !important;
  }
  .custom_head_plac {
    height: 56px;
  }
  .custom_head {
    width: 100%;
    height: 56px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    border-radius: 16px 16px 0 0;

    .title {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333;
      white-space: nowrap;
    }

    .close_out_box {
      position: absolute;
      right: 20px;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }

    .close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .content_in_box {
    padding: 0 20px;
    margin: 20px 0;
    max-height: 72vh;
    overflow: hidden auto;

    &:hover {
      &::-webkit-scrollbar {
        background-color: #eeeeee33;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #eeeeee99;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }
  }
</style>
